<template>
  <div class="Index">
    <div class="banner">
      <el-carousel trigger="click"
                   height="570px">
        <el-carousel-item v-for="(item,index) in bannerList"
                          :key="index">
          <div class="imgbox"
               :style="{backgroundImage:`url(${item.thumb})`}">
            <!-- <img :src="item.thumb"
                 alt=""> -->
          </div>
          <div class="bt">
            <div class="container">
              <img :src="item.tit"
                   alt="">
              <a href="javascript:;"
                 class="_globalBtn theme"
                 @click="dialogVisible = !dialogVisible">Book your place</a>
            </div>
          </div>
        </el-carousel-item>

      </el-carousel>

      <el-dialog v-model="dialogVisible"
                 width="850px"
                 destroy-on-close>
        <div class="applyForm">
          <h3>I want to apply for Chinese University</h3>
          <div>

            <el-form ref="form"
                     :model="applyForm"
                     :rules="rules"
                     label-position='top'>
              <el-form-item label="Proposed start term"
                            prop="start_date">
                <el-radio-group v-model="applyForm.start_date">
                  <el-radio label="2022, Autumn Semester">2022, Autumn Semester</el-radio>
                  <el-radio label="2022, Spring Semester">2022, Spring Semester</el-radio>
                  <el-radio :label="stInput == '' ? 'other':stInput">
                    Other <el-input v-model="stInput"></el-input>
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Proposed subject to study"
                            prop="subject">
                <el-radio-group v-model="applyForm.subject">
                  <el-radio label="Law & Philosophy">Law & Philosophy</el-radio>
                  <el-radio label="Educationr">Educationr</el-radio>
                  <el-radio label="Chinese Language & Culture">Chinese Language & Culture</el-radio>
                  <el-radio label="Science & Engineering">Science & Engineering</el-radio>
                  <el-radio label="Economics & Management">Economics & Management</el-radio>
                  <el-radio label="Literature & Art">Literature & Art</el-radio>
                  <el-radio label="Agriculture & Forestry">Agriculture & Forestry</el-radio>
                  <el-radio label="Medicine">Medicine</el-radio>
                  <el-radio :label="suInput == '' ? 'other':suInput">
                    Other <el-input v-model="suInput"></el-input>
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Proposed Program">
                <el-input v-model="applyForm.program"></el-input>
              </el-form-item>
              <el-form-item label="Intend to apply for Scholarship?">
                <el-radio-group v-model="applyForm.isScholarship">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Proposed start term">
                <el-input v-model="applyForm.name"></el-input>
              </el-form-item>

              <el-form-item label="Preference of University Location">
                <el-radio-group v-model="applyForm.university_location">
                  <el-radio label="No preference">No preference</el-radio>
                  <el-radio label="Beijing">Beijing</el-radio>
                  <el-radio label="Shanghai">Shanghai</el-radio>
                  <el-radio label="Wuhan">Wuhan</el-radio>
                  <el-radio :label="unInput == '' ? 'other':unInput">
                    Other <el-input v-model="unInput"></el-input>
                  </el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="Proposed degree of the program">
                <el-radio-group v-model="applyForm.degree">
                  <el-radio label="Bachelor Degree Program">Bachelor Degree Program</el-radio>
                  <el-radio label="Master Degree Program">Master Degree Program</el-radio>
                  <el-radio label="Ph.D Program">Ph.D Program</el-radio>
                  <el-radio label="Non-degree Program">Non-degree Program</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="Most Advanced Education Background	">
                <el-radio-group v-model="applyForm.education_background">
                  <el-radio label="High School">High School</el-radio>
                  <el-radio label="Bachelor">Bachelor</el-radio>
                  <el-radio label="Master">Master</el-radio>
                  <el-radio label="Doctor">Doctor</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="Chinese Language Proficiency">
                <el-radio-group v-model="applyForm.chinese_language_proficiency">
                  <el-radio label="None">None</el-radio>
                  <el-radio label="A little">A little</el-radio>
                  <el-radio label="Fair">Fair</el-radio>
                  <el-radio label="Good">Good</el-radio>
                  <el-radio label="Excellent">Excellent</el-radio>
                  <div>
                    <el-radio :label="clInput == '' ? 'other':clInput">
                      Please enter the result if you got the HSK certificate <el-input style="width:200px"
                                v-model="clInput"></el-input>
                    </el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Chinese Language Proficiency">
                <el-radio-group v-model="applyForm.english_language_proficiency">
                  <el-radio label="Native">Native</el-radio>
                  <el-radio label="Excellent ">Excellent </el-radio>
                  <el-radio label="Fair">Fair</el-radio>
                  <el-radio label="Poor">Poor</el-radio>
                  <el-radio label="None">None</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Preference of Teaching Language">
                <el-radio-group v-model="applyForm.teaching_language">
                  <el-radio label="Chinese">Chinese</el-radio>
                  <el-radio label="English ">English </el-radio>
                  <el-radio label="Either Chinese or English is OK">Either Chinese or English is OK</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="English Language Proficiency">
                <el-radio-group v-model="applyForm.language_proficiency">

                  <el-radio label="Native">Native</el-radio>
                  <el-radio label="Excellent ">Excellent </el-radio>
                  <el-radio label="Fair">Fair</el-radio>
                  <el-radio label="Poor">Poor</el-radio>
                  <el-radio label="None">None</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Current Location">
                <el-radio-group v-model="applyForm.current_location">
                  <el-radio label="In China">In China</el-radio>
                  <el-radio label="Outside China">Outside China</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Have you studies in China?">
                <el-radio-group v-model="applyForm.is_studies_in_china">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
              <h3>Complete my personal information</h3>
              <el-form-item label="Gender"
                            prop="gender">
                <el-radio-group v-model="applyForm.gender">
                  <el-radio label="Female">Female</el-radio>
                  <el-radio label="Male">Male</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Full name "
                            prop="full_name">
                <el-input v-model="applyForm.full_name"></el-input>
              </el-form-item>
              <el-form-item label="Country or Region"
                            prop="country_or_region">
                <el-select v-model="applyForm.country_or_region"
                           filterable
                           placeholder="please select your zone">
                  <el-option v-for="(item,index) in CountryOrRegionInfo"
                             :key="index"
                             :label="item.value"
                             :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Email "
                            prop="email">
                <el-input v-model="applyForm.email"></el-input>
              </el-form-item>
              <el-form-item label="Tel "
                            prop="tel">
                <el-input v-model="applyForm.tel"></el-input>
              </el-form-item>
              <el-form-item label="Mobile "
                            prop="mobile">
                <el-input v-model="applyForm.mobile"></el-input>
                <p>Please enter it in full. Eg. 0086 27 87446076</p>
              </el-form-item>
              <el-form-item>
                <el-button type="primary"
                           @click="onSubmit('form')">Submit</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>

    </div>

    <div class="about pd40">
      <div class="container">
        <div class="headTit">
          <h3>About <span>ZHUXUE</span></h3>
        </div>
        <div class="aboutCon">
          <div class="left">
            <h3>
              We aim to serve International Students with all our effort<br>
              Provide the most valuable information for students studying abroad
            </h3>
            <p>ZHUXUE began its operation in 2006, so far it has had 15 years experience in international education field. In response to the increasing demand of international students to study in China, ZHUXUE has gradually developed a network that provides a convenient way for international students to search useful information and get direct contact with University Admissions. ZHUXUE network has critical online resources needed and connected international students all over the world. Our goal is to help these students make their study dreams coming true, broaden their international mindset and global vision, enhance their understanding of education and increase bilateral exchanges in all aspects. </p>
            <el-row class="list"
                    :gutter="24">
              <el-col :span="8">
                <div class="con">
                  <router-link to="/select-school">
                    <div class="ico">
                      <i class="iconfont">&#xe9e9;</i>
                    </div>
                    <h3>City</h3>
                  </router-link>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="con">
                  <router-link to="/recommend">
                    <div class="ico">
                      <i class="iconfont">&#xe6d1;</i>
                    </div>
                    <h3>University</h3>
                  </router-link>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="con">
                  <router-link to="/popular-major">
                    <div class="ico">
                      <i class="iconfont">&#xe706;</i>
                    </div>
                    <h3>Major</h3>
                  </router-link>
                </div>
              </el-col>

            </el-row>
            <a href="/home/about"
               class="_globalBtn">View More</a>
          </div>
          <div class="imgbox">
            <img src="../assets/about2.png"
                 alt="">
            <div class="_abo">
              <router-link to="/scholarship"
                           class="lis">
                <i class="imgbox"><img src="@/assets/sico1.png"
                       alt=""></i>
                <div>
                  <p>Scholarship</p>
                </div>
              </router-link>
              <router-link to="popular-major"
                           class="lis">
                <i class="imgbox"><img src="@/assets/sico2.png"
                       alt=""></i>
                <div>
                  <p>University search</p>
                </div>
              </router-link>
              <a href="javascript:;"
                 @click="onShowVioForm()"
                 class="lis">
                <i class="imgbox"><img src="@/assets/sico3.png"
                       alt=""></i>
                <div>
                  <p>Consulting service</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chooseUni pd40">
      <div class="container">
        <div class="headTit">
          <h3>University Search</h3>
        </div>
        <div class="searchBar">
          <div class="item">
            <router-link to="/popular-schools"><i class="iconfont">&#xe634;</i>ranking</router-link>
          </div>
          <div class="item">
            <el-cascader :props="props"
                         class="se-city"
                         v-model="form.city"
                         placeholder="Select City"></el-cascader>
          </div>
          <div class="item">
            <el-select v-model="form.xw"
                       class="se-edu"
                       @change="onXw"
                       placeholder="Select Qualifications">
              <el-option v-for="item in xw"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <el-select v-model="form.major"
                       class="se-major"
                       @change="onMajor"
                       placeholder="Select Major">
              <el-option v-for="item in major"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <el-input placeholder="Input Keywords"
                      v-model="form.searchVal"
                      @keyup.enter="onSearch"
                      clearable></el-input>
          </div>
          <div class="item">
            <el-button type="danger"
                       @click="onSearch"> <i class="el-icon-search"></i> Search</el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="hezuo pd40">
      <div class="container">
        <div class="headTit">
          <h3>ZHUXUE <span>Highlight</span></h3>
        </div>
        <div>
          <swiper :autoplay="swiper_options.autoplay"
                  :loop="false"
                  :speed="swiper_options.speed"
                  :pagination="swiper_options.pagination"
                  :navigation="swiper_options.navigation"
                  :spaceBetween="swiper_options.spaceBetween"
                  :centeredSlides="swiper_options.centeredSlides"
                  :slidesPerView="swiper_options.slidesPerView"
                  class="swiper"
                  effect="coverflow">
            <swiper-slide class="hezuoLis"
                          v-for="(item,index) in recList"
                          :key="index">
              <div class="imgbox">
                <img :src="item.thumb"
                     alt="">
              </div>
              <div class="text">
                <h3>{{item.en_name}}</h3>
                <p>{{item.desc}}</p>
                <a :href="'/home/school-detail/'+item.id">more</a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>

    <div class="youshi pd40">
      <div class="container">
        <div class="headTit">
          <h3>Our Advantage</h3>
        </div>
        <el-row class="list"
                :gutter="24">
          <el-col :span="6">
            <div class="con">
              <div class="imgbox">
                <img src="../assets/youshi2.png"
                     alt="">
              </div>
              <h3>
                <Countup v-if="countFlag"
                         :value="countNum[0]"></Countup> +
              </h3>
              <h4>
                <router-link to="/recommend">Universities</router-link>
              </h4>
              <p>ZHUXUE cooperates with more than 400 universities and colleges in China, and the number continues to grow.</p>
              <a href="/home/recommend"
                 class="_globalBtn">View More</a>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="con">
              <div class="imgbox">
                <img src="../assets/youshi3.png"
                     alt="">
              </div>
              <h3>
                <Countup v-if="countFlag"
                         :value="countNum[1]"></Countup>+
              </h3>
              <h4>
                <router-link to="/popular-major">High-end technology projects</router-link>
              </h4>
              <p>Thanks to our cooperative universities and colleges, we have more than 300 high-tech talent training projects for you to choose</p>

              <a href="/home/popular-major"
                 class="_globalBtn">View More</a>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="con">
              <div class="imgbox">
                <img src="../assets/youshi4.png"
                     alt="">
              </div>
              <h3>
                <Countup v-if="countFlag"
                         :value="countNum[2]"></Countup>+
              </h3>
              <h4>
                <router-link to="scholarship">Scholarship programs</router-link>
              </h4>
              <p>ZHUXUE has cooperated with hundreds of scholarship programs. All these universities put their scholarship projects on the ZHUXUE platform</p>
              <a href="/home/scholarship"
                 class="_globalBtn">View More</a>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="con">
              <div class="imgbox">
                <img src="../assets/youshi5.png"
                     alt="">
              </div>
              <h3>
                <Countup v-if="countFlag"
                         :value="countNum[3]"></Countup>+
              </h3>
              <h4>
                <router-link to="/select-school">Cities to choose</router-link>
              </h4>
              <p>ZHUXUE can help you choose universities in more than 70 cities in China. you can always find your destination to study in China.</p>
              <a href="/home/select-school"
                 class="_globalBtn">View More</a>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="server pd40">
      <div class="container">
        <div class="headTit">
          <h3>International Student <span>Service</span></h3>
        </div>
        <el-row class="list"
                :gutter="24">
          <el-col :span="4">
            <a href="javascript:;"
               class="con">
              <el-popover placement="bottom"
                          title="Contact"
                          :width="300"
                          trigger="click">
                <p>Tel: 86-10-62040776</p>
                <p>Email: zhuxueedu@gmail.com</p>
                <template #reference>
                  <li>
                    <div class="ico">
                      <i class="iconfont">&#xe68d;</i>
                    </div>
                    <h3>Direct to uni</h3>
                  </li>
                </template>
              </el-popover>
            </a>
          </el-col>
          <el-col :span="4">
            <a href="/home/scholarship"
               class="con co2">
              <li>
                <div class="ico">
                  <i class="iconfont">&#xe606;</i>
                </div>
                <h3>Scholarship</h3>
              </li>
            </a>
          </el-col>
          <el-col :span="4">
            <a href="/home/visa-center-detail/index"
               class="con co3">
              <li>
                <div class="ico">
                  <i class="iconfont">&#xe62c;</i>
                </div>
                <h3>Visa Application</h3>
              </li>
            </a>
          </el-col>
          <el-col :span="4">
            <a href="/home/zhusu"
               class="con co4">
              <li>
                <div class="ico">
                  <i class="iconfont">&#xe654;</i>
                </div>
                <h3>Accommodation</h3>
              </li>
            </a>
          </el-col>
          <el-col :span="4">
            <a href="/home/jieji"
               class="con co5">
              <li>
                <div class="ico">
                  <i class="iconfont">&#xe6d0;</i>
                </div>
                <h3>Airport Pickup</h3>
              </li>
            </a>
          </el-col>

          <el-col :span="4">
            <a href="/home/yuyan"
               class="con co6">
              <li>
                <div class="ico">
                  <i class="iconfont">&#xe72e;</i>
                </div>
                <h3>Other Services</h3>
              </li>
            </a>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="ysxiangmu pd40">
      <div class="container">
        <div class="headTit">
          <h3>ZHUXUE Outstanding Programmes</h3>
        </div>
        <div class="ysxiangmuCon">
          <swiper :autoplay="swiper_options.autoplay"
                  :loop="false"
                  :speed="swiper_options.speed"
                  :navigation="{
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev'
        }"
                  :centeredSlides="swiper_options.centeredSlides"
                  :slidesPerView="4"
                  class="swiper"
                  effect="coverflow">
            <swiper-slide class="lis"
                          v-for="(item,index) in ysList"
                          :key="index"
                          @click="onPage(item.video)">
              <div class="imgbox">
                <img :src="item.thumb"
                     alt="">
              </div>
              <div class="text">
                <h3>{{item.title}}</h3>
                <p>{{item.description}}</p>
              </div>

            </swiper-slide>
            <div class="swiperNav swiper-next">
              <el-icon>
                <arrow-right-bold />
              </el-icon>
            </div>
            <div class="swiperNav swiper-prev">
              <el-icon>
                <arrow-left-bold />
              </el-icon>
            </div>
          </swiper>

        </div>
      </div>

    </div>
    <div class="zhuxue pd40">
      <div class="container">
        <div class="headTit">
          <h3>ZHUXUE <span>China</span></h3>
        </div>
        <div class="list">
          <swiper :autoplay="swiper_options.autoplay"
                  :loop="false"
                  @swiper="onVidSwiper"
                  :speed="swiper_options.speed"
                  :pagination="swiper_options.pagination"
                  :spaceBetween="20"
                  :centeredSlides="false"
                  :slidesPerView="3"
                  class="swiper"
                  effect="coverflow">
            <swiper-slide class="hezuoLis"
                          v-for="(item,index) in videoList"
                          :key="index">
              <div class="con"
                   @click="onShowVideo(item)">
                <div class="imgbox">
                  <img :src="item.thumb"
                       alt="">
                </div>
                <p>
                  {{item.title}}
                </p>
              </div>
            </swiper-slide>
          </swiper>
        </div>

      </div>
      <el-dialog v-model="showVideoFlag"
                 width="800px"
                 custom-class="videoBlo"
                 destroy-on-close>
        <div class="applyVideo">
          <div v-if="showVideoFlag">
            <div class="videoBox">
              <vue3-video-player :title="videoDetail.name"
                                 :cover="videoDetail.thumb"
                                 :autoplay="true"
                                 :play="vidPlay"
                                 :src="videoDetail.video"></vue3-video-player>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="xinwen pd40">
      <div class="container">
        <div class="headTit">
          <h3><span>News</span></h3>
        </div>
        <el-row class="list"
                :gutter="24">
          <el-col :span="12">
            <router-link :to="'/news-detail/'+hotNews.id"
                         class="hotNews">
              <div class="imgbox">
                <img :src="hotNews.thumb"
                     alt="">
              </div>
              <div class="date">
                <i class="el-icon-date"></i> {{hotNews.updated_at}}
              </div>
              <div class="head">
                {{hotNews.title}}
              </div>
            </router-link>
          </el-col>
          <el-col :span="12">
            <router-link :to="'/news-detail/'+hotNews.id"
                         class="news"
                         v-for="(item,index) in newsList"
                         :key="index">
              <el-row class="list"
                      :gutter="24">
                <el-col :span="9">
                  <div class="imgbox">
                    <img :src="item.thumb"
                         alt="">
                  </div>
                </el-col>
                <el-col :span="15">
                  <div class="text">
                    <div class="date">
                      <i class="el-icon-date"></i> {{item.updated_at}}
                    </div>
                    <div class="head">
                      {{item.title}}
                    </div>
                    <div class="cont">
                      {{item.description}}
                    </div>
                  </div>
                </el-col>
              </el-row>
            </router-link>
          </el-col>

        </el-row>
        <router-link to="/news"
                     class="_globalBtn">View More</router-link>

      </div>
    </div>
    <div class="wenti pd40">
      <div class="container">
        <div class="headTit">
          <h3><span>FAQs</span></h3>
        </div>
        <div class="list">
          <el-row :gutter="24">
            <el-col :span="12"
                    v-for="(item,index) in qaList"
                    :key="index">
              <a href="/home/faq"
                 class="lis">
                <div class="qu">
                  <div class="imgbox">
                    <img src="../assets/qu.png"
                         alt="">
                  </div>
                  <p>{{item.title}}</p>
                </div>
                <div class="as">
                  <div class="imgbox">
                    <img src="../assets/as.png"
                         alt="">
                  </div>
                  <div v-html="item.content"
                       class="spa"></div>
                </div>
              </a>
            </el-col>
          </el-row>
        </div>
        <router-link to="/faq"
                     class="_globalBtn">View More</router-link>

      </div>
    </div>
    <ComForm ref='vioForm'
             :typeId='7'
             formTitle="Consulting service"></ComForm>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
import Countup from '../components/count-to/count-to'
import { onBeforeMount, reactive, markRaw, toRaw, ref } from "vue";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
require("swiper/swiper.min.css");
require("swiper/components/pagination/pagination.scss");
require("swiper/components/navigation/navigation.scss");
import { ElMessage } from 'element-plus'
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons'
import ComForm from "@/components/ComForm.vue";


SwiperCore.use([Autoplay, Pagination, Navigation]);
export default {
  components: {
    Countup,
    Swiper,
    SwiperSlide,
    ArrowLeftBold,
    ArrowRightBold,
    ComForm

  },
  data () {
    return {
      dialogVisible: false,
      showVideoFlag: false,
      showVideoBlo: false,
      videoDetail: {},
      bannerList: [
        {
          thumb: require('@/assets/banner.png'),
          tit: require('@/assets/b1.png'),
        },
        {
          thumb: require('@/assets/h2-2.jpg'),
          tit: require('@/assets/b2.png'),
        },
      ],
      university: [
        {
          value: '北京大学',
          label: '北京大学',
        },
        {
          value: '清华大学',
          label: '清华大学',
        },
        {
          value: '西北大学',
          label: '西北大学',
        },
        {
          value: '家里蹲大学',
          label: '家里蹲大学',
        },
      ],
      city: [
        {
          value: '北京',
          label: '北京',
        },
        {
          value: '西安',
          label: '西安',
        },
        {
          value: '上海',
          label: '上海',
        },
        {
          value: '深圳',
          label: '深圳',
        },
      ],
      swloop: true,
      major: [],
      zhongzhuan: [],
      linshiMajor: [],
      xw: [
        {
          value: 'undergraduate',
          label: 'Undergraduate'
        },
        {
          value: 'masters_degree',
          label: 'Masters Degree'
        },
        {
          value: 'doctoral',
          label: 'Doctoral'
        },
      ],
      cgXw: '',
      cgMajor: '',
      searchData: {
        university: '',
        city: '',
        edu: '',
        major: '',
        searchVal: ''
      },
      hotNews: {
        id: '01',
        imgSrc: require('@/assets/news.png'),
        title: '新闻标题',
        content: '新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容',
        date: '2020-02-20'
      },
      newsList: [
        {
          id: '01',
          imgSrc: require('@/assets/news.png'),
          title: '新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题',
          content: '新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容',
          date: '2020-02-20'
        }, {
          id: '01',
          imgSrc: require('@/assets/news.png'),
          title: '新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题',
          content: '新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容',
          date: '2020-02-20'
        }, {
          id: '01',
          imgSrc: require('@/assets/news.png'),
          title: '新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题',
          content: '新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容',
          date: '2020-02-20'
        }
      ],
      qaList: [],
      eduList: [
        {
          img: require('@/assets/news.png'),
          title: '西北大学',
          add: '陕西西安',
          rank: '20'
        }, {
          img: require('@/assets/news.png'),
          title: '西北大学',
          add: '陕西西安',
          rank: '20'
        }, {
          img: require('@/assets/news.png'),
          title: '西北大学',
          add: '陕西西安',
          rank: '20'
        }, {
          img: require('@/assets/news.png'),
          title: '西北大学',
          add: '陕西西安',
          rank: '20'
        }, {
          img: require('@/assets/news.png'),
          title: '西北大学',
          add: '陕西西安',
          rank: '20'
        }, {
          img: require('@/assets/news.png'),
          title: '西北大学',
          add: '陕西西安',
          rank: '20'
        }
      ],
      form: {
        city: '',
        major: '',
        xw: '',
        searchVal: ''
      },
      videoList: [],
      recList: [],
      ysList: [],
      countFlag: false,
      countNum: [400, 300, 2000, 70],
      loading: false,
      cityName: '陕西',
      applyForm: {
        start_date: '',
        subject: '',
        program: '',
        isScholarship: '',
        university_location: '',
        degree: '',
        education_background: '',
        chinese_language_proficiency: '',
        english_language_proficiency: '',
        teaching_language: '',
        language_proficiency: '',
        current_location: '',
        is_studies_in_china: '',
        gender: '',
        full_name: '',
        country_or_region: '',
        email: '',
        tel: '',
        mobile: ''
      },
      rules: {
        gender: [
          {
            required: true,
            message: 'Please select gender',
            trigger: 'blur',
          },
        ],
        full_name: [
          {
            required: true,
            message: 'Please input Full Name',
            trigger: 'blur',
          },
        ],
        country_or_region: [
          {
            required: true,
            message: 'Please select country or region',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input email',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Please input correct email address',
            trigger: ['blur', 'change'],
          },
        ],
        tel: [
          {
            required: true,
            message: 'Please input tel',
            trigger: 'blur',
          },
        ],
        mobile: [
          {
            required: true,
            message: 'Please input mobile',
            trigger: 'blur',
          },
        ],
      },
      onSi: {},
      viedeoFlag: false,
    }
  },
  setup () {
    const state = reactive({
      props: {
        lazy: true,
        lazyLoad (node, resolve) {
          const { level, value } = node
          let arr = []
          if (level > 0) {
            axiosApi(
              'university/province/' + value, {
            }, 'get'
            ).then((res) => {
              res.data.forEach((item) => {
                arr = [...arr, {
                  value: item.code,
                  label: item.en_name,
                  leaf: level >= 1
                }]
              })
              resolve(arr)
            })
          } else {
            axiosApi(
              'university/province', {
            }, 'get'
            ).then((res) => {
              res.data.forEach((item) => {
                arr = [...arr, {
                  value: item.id,
                  label: item.en_name,
                }]
              })
              resolve(arr)
            })
          }
        },
      },
    })
    let swiper_options = reactive({
      autoplay: {
        disableOnInteraction: false, // 鼠标滑动后继续自动播放
        delay: 4000, //4秒切换一次
      },
      speed: 500, //切换过渡速度
      loop: true,
      slidesPerView: 3, //解决最后一张切换到第一张中间的空白
      // centeredSlides: true, //设置slide居中
      spaceBetween: 40,
      pagination: {
        clickable: true,
      },
    });
    // 将swiper_options返回给模板中的swiper组件使用
    const opErrorMsg = (val) => {
      ElMessage.error(val)
    }
    const stInput = ref('')
    const suInput = ref('')
    const unInput = ref('')
    const clInput = ref('')
    const CountryOrRegionInfo = ref([])
    onBeforeMount(() => {
      axiosApi('CountryOrRegion', {}, 'get').then(res => {
        CountryOrRegionInfo.value = res.data
      })
    })

    const vioForm = ref()
    const onShowVioForm = () => {
      vioForm.value.onShowVio()
    }
    return {
      swiper_options,
      ...state,
      opErrorMsg,
      stInput,
      suInput,
      unInput,
      clInput,
      CountryOrRegionInfo,
      onShowVioForm,
      vioForm,
    };
  },
  computed: {
    player () {
      return this.$refs.videoPlayer.player
    },
    disabled () {
      if (this.eduList.length > 30) {
        return true
      } else {
        return false
      }
    },
  },
  beforeMount: function () {
    let navItem = document.querySelectorAll('.indexItem')
    navItem[0].classList.add('on')
    axiosApi(
      'university/recommend', {}, 'get'
    ).then((res) => {
      this.recList = res.data.data
    })
    axiosApi(
      'news/22', {}, 'get'
    ).then((res) => {
      this.ysList = res.data.data
    })
    axiosApi(
      'news/21', {}, 'get'
    ).then((res) => {
      this.videoList = res.data.data
    })
    axiosApi(
      'cate/1', {}, 'get'
    ).then((res) => {
      return axiosApi(
        'news/' + res.data[0].id, {}, 'get'
      )
    }).then((res) => {
      this.hotNews = res.data.data[0]
      this.newsList = res.data.data.slice(1, 4)
    })

    axiosApi(
      'university/vocational', {
    }, 'get'
    ).then((res) => {
      let arr = []
      this.zhongzhuan = markRaw(res.data)
      res.data.forEach((item) => {
        let doctoral = item.doctoral ? item.doctoral : ''
        if (doctoral.length > 0) {
          doctoral.forEach((item) => {
            item.father = 'doctoral'
          })
        }
        let undergraduate = item.undergraduate ? item.undergraduate : ''
        if (undergraduate.length > 0) {
          undergraduate.forEach((item) => {
            item.father = 'undergraduate'
          })
        }
        let masters_degree = item.masters_degree ? item.masters_degree : ''
        if (masters_degree.length > 0) {
          masters_degree.forEach((item) => {
            item.father = 'masters_degree'
          })
        }
        arr = [...arr, ...doctoral, ...undergraduate, ...masters_degree]
      })
      let deWeightThree = () => {
        let map = new Map();
        for (let item of arr) {
          if (!map.has(item.program)) {
            map.set(item.program, item);
          }
        }
        return [...map.values()];
      }
      let nArr = deWeightThree()
      let nMajor = []
      nArr.forEach((item) => {
        nMajor = [...nMajor, {
          value: item.program,
          label: item.program,
          fa: item.father
        }]
      })
      this.linshiMajor = markRaw(nMajor)
      this.major = nMajor
    })

    axiosApi(
      'news/2', {}, 'get'
    ).then((res) => {
      this.qaList = res.data.data.length > 4 ? res.data.data.splice(3) : res.data.data
    })
  },
  mounted: function () {
    this.$nextTick(function () {
      window.addEventListener('scroll', this.onScroll)
    })
    this.showVideoFlag = true
    this.videoDetail = {
      name: 'zhuxue',
      thumb: '',
      video: 'http://zhuxue.net/vid/video1.mp4'
    }
  },
  beforeUnmount: function () {
    this.$nextTick(function () {
      window.removeEventListener('scroll', this.onScroll)
    })
  },
  activated: function () {
    this.$nextTick(function () {
      window.addEventListener('scroll', this.onScroll)
    })
  },
  deactivated: function () {
    this.$nextTick(function () {
      window.removeEventListener('scroll', this.onScroll)
    })
  },
  methods: {
    onXw (val) {
      this.cgXw = val
    },
    onMajor (val) {
      this.cgMajor = val
    },
    onScroll () {
      const scrollTo = document.querySelector('.youshi').offsetTop - window.screen.height
      let scrolled = document.documentElement.scrollTop || document.body.scrollTop
      // 586、1063分别为第二个和第三个锚点对应的距离
      if (scrolled >= scrollTo && !this.countFlag) {
        this.countFlag = true
      }
      if (scrolled < scrollTo) {
        this.countFlag = false
      }
      if (scrolled > (scrollTo + window.screen.height + 600)) {
        this.countFlag = false
      }
    },
    load () {
      this.loading = true
      setTimeout(() => {
        this.eduList = [...this.eduList, {
          img: require('@/assets/news.png'),
          title: '大学名字'
        }, {
          img: require('@/assets/news.png'),
          title: '大学名字'
        }, {
          img: require('@/assets/news.png'),
          title: '大学名字'
        }]
        this.loading = false
      }, 2000)
    },
    onSearch () {
      this.$router.push({ path: '/popular-major', query: { flag: 1, city: this.form.city[1] ? this.form.city[1] : '', xw: this.form.xw, major: this.form.major, searchVal: this.form.searchVal } })
    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axiosApi('message', {
            ...this.applyForm
          }, 'post').then(res => {
            if (res.data.status) {
              ElMessage.success('success')
              this.dialogVisible = false
              this.$refs[formName].resetFields()
            } else {
              ElMessage.error('error')
            }
          })
        } else {
          return false
        }
      })
    },
    onPage (links) {
      if (!links) return true
      if (links.length > 0) {
        window.location.href = links
      }
    },
    onVidSwiper (e) {
      this.onSi = toRaw(e)
    },
    onShowVideo (val) {
      this.showVideoFlag = true
      this.videoDetail = val

    }
  },
  watch: {
    cgXw (val) {
      let oldVal = this.zhongzhuan
      let arr = []
      oldVal.forEach((item) => {
        let doctoral = null
        if (val == 'doctoral') {
          doctoral = item.doctoral ? item.doctoral : ''
          if (doctoral.length > 0) {
            doctoral.forEach((item) => {
              item.father = 'doctoral'
            })
          }
        }
        if (val == 'undergraduate') {
          doctoral = item.undergraduate ? item.undergraduate : ''
          if (doctoral.length > 0) {
            doctoral.forEach((item) => {
              item.father = 'undergraduate'
            })
          }
        }
        if (val == 'masters_degree') {
          doctoral = item.masters_degree ? item.masters_degree : ''
          if (doctoral.length > 0) {
            doctoral.forEach((item) => {
              item.father = 'masters_degree'
            })
          }
        }
        arr = [...arr, ...doctoral]
      })
      let deWeightThree = () => {
        let map = new Map();
        for (let item of arr) {
          if (!map.has(item.program)) {
            map.set(item.program, item);
          }
        }
        return [...map.values()];
      }
      let nArr = deWeightThree()
      let nMajor = []
      nArr.forEach((item) => {
        nMajor = [...nMajor, {
          value: item.program,
          label: item.program,
          fa: item.father
        }]
      })
      let nval = this.form
      nval.major = nMajor[0].value
      this.form = nval
      this.major = nMajor
    },
    cgMajor (val) {
      console.log(val)
      let oldArr = this.linshiMajor
      let arr = []
      oldArr.forEach(item => {
        if (item.value == val) {
          arr = [...arr, item.fa]
        }
      })
      let nval = this.form
      nval.xw = arr[0]
      this.form = nval
    },
    showVideoFlag (val) {
      if (!val) {
        this.onSi.autoplay.start()
      } else {
        this.onSi.autoplay.stop()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  .imgbox {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .bt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    a {
      margin: 0;
      margin-top: 20px;
    }
  }
}
.applyForm ::v-deep {
  h3 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  .el-form-item__label {
    line-height: 1.5;
    font-weight: bold;
    color: #0c3879;
  }
}

.about {
  .aboutCon {
    display: flex;
    .left {
      padding-top: 20px;
      width: 50%;
      h3 {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 10px;
      }
      p {
        color: #616161;
        margin-bottom: 10px;
        line-height: 1.5;
        font-size: 14px;
      }

      .list {
        margin-top: 15px;
        .con {
          box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: 0.1);
          padding: 10px;
          border-radius: 8px;
          transition: ease 0.5s;
          &:hover {
            background-color: #ffc107;
            .ico {
              transform: rotateY(360deg);
            }
          }
        }
        .ico {
          width: 60px;
          height: 60px;
          text-align: center;
          margin: auto;
          line-height: 60px;
          color: #004168;
          transition: ease 1s;
          i {
            font-size: 48px;
          }
        }
        h3 {
          font-size: 16px;
          font-weight: normal;
          margin-bottom: 0;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
    .imgbox {
      width: 50%;
      padding-left: 20px;
      position: relative;
      ._abo {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translate(30px, -30px);
        color: #fff;
        .lis {
          width: 300px;
          padding-left: 30px;
          position: relative;
          height: 100px;
          display: flex;
          align-items: center;
          .imgbox {
            width: 55px;
            height: 55px;
            padding-left: 0;
            transition: ease 0.5s;
            margin-right: 15px;
          }
          &::before {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            left: 0;
            top: 44px;
            border-radius: 50%;
            border: 2px solid #fff;
          }
          &::after {
            position: absolute;
            content: "";
            width: 2px;
            height: 90px;
            left: 6px;
            top: 56px;
            background-color: #fff;
          }
          &:last-child::after {
            display: none;
          }
          &:hover {
            .imgbox {
              transform: rotateY(180deg);
            }
          }
        }
      }
    }
  }
}
.chooseUni {
  padding: 60px;
  background: url(~@/assets/cho.png) center center no-repeat;
  .headTit {
    color: #fff;
    p {
      color: #fff;
    }
  }
  .searchBar {
    display: flex;
    border-radius: 8px;
    margin-top: 60px;
    box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: 0.2);
    background-color: #fff;
    margin-bottom: 40px;
    overflow: hidden;
    background-color: #fff;
    a {
      display: block;
      line-height: 40px;
      text-align: center;
      width: 100px;
      color: #004168;
      font-weight: bold;
    }
    .el-select ::v-deep {
      .el-input__inner {
        border: none;
        width: 200px;
        padding-left: 36px;
        --el-input-placeholder-color: #999;
        color: #333;
      }
    }
    .el-input ::v-deep {
      .el-input__inner {
        border: none;
        width: 300px;
        padding-left: 36px;
        background: url(~@/assets/ico4.png) 10px center no-repeat;
        background-size: 16px;
        --el-input-placeholder-color: #999;
        color: #333;
      }
    }
    .se-city ::v-deep {
      .el-input__inner {
        background: url(~@/assets/ico1.png) 10px center no-repeat;
        background-size: 16px;
      }
    }
    .se-edu ::v-deep {
      .el-input__inner {
        background: url(~@/assets/ico2.png) 10px center no-repeat;
        background-size: 16px;
      }
    }
    .se-major ::v-deep {
      .el-input__inner {
        background: url(~@/assets/ico3.png) 10px center no-repeat;
        background-size: 16px;
      }
    }
    .el-button {
      width: 200px;
      background-color: #ffc107;
      border-color: #ffc107;
      color: #333;
    }
    & ::v-deep {
      .se-city {
        .el-input__inner {
          border: none;
          width: 200px;
          padding-left: 36px;
          --el-input-placeholder-color: #999;
          color: #333;
          background: url(~@/assets/ico1.png) 10px center no-repeat;
          background-size: 16px;
        }
      }
    }
  }
}
.server {
  .list {
    .con {
      display: block;
      padding: 20px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 8px;
      transition: ease 0.5s;
      margin-bottom: 20px;
      li {
        width: 100%;
        box-sizing: border-box;
        transition: ease 0.5s;
        .ico {
          width: 80px;
          height: 80px;
          display: flex;
          overflow: hidden;
          align-items: center;
          justify-content: center;
          transition: ease 0.5s;
          margin: 0 auto 10px;
          i {
            font-size: 64px;
            transition: ease 0.5s;
            color: #004168;
          }
        }
        h3 {
          font-size: 18px;
          text-align: center;
          transition: ease 0.5s;
          color: #004168;
        }
        p {
          font-size: 14px;
          color: #666;
          height: 48px;
          transition: ease 0.5s;
          text-align: center;
          line-height: 24px;
        }
      }
      &.co2 {
        li {
          .ico {
            i {
              color: #3c5384;
            }
          }
          h3 {
            color: #3c5384;
          }
        }
      }
      &.co3 {
        li {
          .ico {
            i {
              color: #6a649d;
            }
          }
          h3 {
            color: #6a649d;
          }
        }
      }
      &.co4 {
        li {
          .ico {
            i {
              color: #9975b1;
            }
          }
          h3 {
            color: #9975b1;
          }
        }
      }
      &.co5 {
        li {
          .ico {
            i {
              color: #c986c0;
            }
          }
          h3 {
            color: #c986c0;
          }
        }
      }
      &.co6 {
        li {
          .ico {
            i {
              color: #f798c9;
            }
          }
          h3 {
            color: #f798c9;
          }
        }
      }
      &:hover {
        background-color: #004168;
        &.co2 {
          background-color: #3c5384;
        }
        &.co3 {
          background-color: #6a649d;
        }
        &.co4 {
          background-color: #9975b1;
        }
        &.co5 {
          background-color: #c986c0;
        }
        &.co6 {
          background-color: #f798c9;
        }
        li {
          .ico {
            i {
              color: #fff;
            }
          }
          h3 {
            color: #fff;
          }
          p {
            color: #fff;
          }
        }
      }
    }
  }
}
.youshi {
  padding: 60px;
  background: url(~@/assets/youshi1.png) center center no-repeat;
  .headTit {
    color: #fff;
    p {
      color: #fff;
    }
  }
  .list {
    .con {
      padding: 20px 30px;
      box-sizing: border-box;
      background: url(~@/assets/youshibgs.png) top right no-repeat #fff;
      .imgbox {
        width: max-content;
        height: 60px;
      }
      h4 {
        margin-top: 20px;
        text-align: center;
        font-size: 18px;
        height: 54px;
      }
      h3 {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        font-size: 55px;
        color: #004168;
      }
      p {
        margin-top: 20px;
        font-size: 14px;
        min-height: 160px;
        color: #666;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.zhuxue {
  background: #f3f6f8;
  .swiper-container {
    padding-bottom: 40px;
  }
  .imgbox {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background: url(~@/assets/bf.png) center center no-repeat;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .videoBox {
    width: 100%;
    min-height: 200px;
    .vcp-container ::v-deep {
      .vcp-dashboard {
        width: 100% !important;
        margin-left: 0;
      }
      .play-pause-layer .btn-control::after {
        content: "";
        width: 50px;
        height: 50px;
        background: url(~@/assets/vid.png) 18px center no-repeat;
        background-size: 35%;
      }
      .play-pause-layer .btn-control .btn-play {
        display: none;
      }
      .volume-control .btn-control-panel .volume-info {
        left: -10px;
      }
      .btn-control .tips {
        bottom: 50px;
      }
      .volume-control .btn-control-panel {
        padding: 10px 0;
        height: 100px;
        width: 20px;
      }
      .volume-control .btn-control-panel .progress {
        height: 80px;
        margin-top: -40px;
      }
      .volume-control .btn-control-panel .progress .volume-current,
      .vcp-progress-played {
        background-color: #004168;
      }
      .vcp-switch:after {
        background-color: #004168;
      }
    }
  }
  .list {
    p {
      margin-top: 10px;
      text-align: center;
    }
  }
  .hezuoLis {
    cursor: pointer;
  }
}

.xinwen {
  .hotNews {
    display: block;
    .imgbox {
      img {
        width: 100%;
      }
    }
    .date {
      margin-top: 10px;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #666;
      i {
        font-size: 20px;
        color: #004168;
        margin-right: 5px;
      }
    }
    .head {
      font-size: 18px;
      font-weight: bold;
      margin-top: 10px;
      line-height: 30px;
      height: 30px;
      transition: ease 0.5s;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:hover {
      color: #004168;
    }
  }
  .news {
    display: block;
    margin-bottom: 25px;
    .imgbox {
      height: 140px;
    }
    .date {
      color: #004168;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .head {
      font-weight: bold;
      margin-bottom: 10px;
      line-height: 24px;
      height: 48px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      transition: ease 0.5s;
    }
    .cont {
      font-size: 14px;
      color: #666;
      transition: ease 0.5s;
      line-height: 20px;
      height: 40px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      .head {
        color: #004168;
      }
    }
  }
  & .container > a {
    display: block;
    margin: auto;
    margin-top: 40px;
    width: max-content;
    padding: 10px 40px;
    color: #fff;
  }
}
.wenti {
  background: #f3f6f8;

  .qu {
    display: flex;
    align-items: center;
    .imgbox {
      width: 30px;
      margin-right: 10px;
    }
    p {
      width: calc(100% - 40px);
      transition: ease 0.5s;
      font-weight: bold;
      font-size: 18px;
    }
    margin-bottom: 15px;
  }
  .as {
    display: flex;
    .imgbox {
      width: 30px;
      margin-right: 10px;
    }
    .spa ::v-deep {
      height: 100px;
      overflow: hidden;
      font-size: 14px;
      line-height: 1.5;
      color: #616161;
      width: calc(100% - 40px);
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      p {
        span {
          transition: ease 0.5s;
        }
      }
    }
  }
  .lis {
    display: block;
    background-color: #fff;
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 30px 20px;
    box-shadow: 4px 4px 6px rgba($color: #000000, $alpha: 0.1);
    transition: ease 0.5s;
    &:hover ::v-deep {
      background-color: #004168;
      p {
        color: #fff !important;
        span {
          color: #fff !important;
        }
      }
    }
  }
}
.hezuo {
  .infinite-list-item {
    padding: 10px;
    color: #666;
    display: flex;
    .imgbox {
      width: 150px;
    }
    .text {
      width: calc(100% - 150px);
      padding-left: 20px;
      h3 {
        color: #212121;
        line-height: 28px;
        font-size: 16px;
      }
      p {
        line-height: 1.7;
        font-size: 14px;
      }
    }
  }
  .hezuoLis {
    .text {
      padding: 20px 0;
      h3 {
        margin-bottom: 10px;
        color: #333;
        font-size: 16px;
        height: 45px;
      }
      p {
        height: 100px;
        line-height: 25px;
        font-size: 14px;
        color: #454545;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
      a {
        display: inline-block;
        padding: 5px 20px;
        border: 1px solid #0c3879;
        color: #0c3879;
        margin-top: 10px;
        &:hover {
          background-color: #0c3879;
          color: #fff;
          transition: ease 0.5s;
        }
      }
    }
  }
  .edulistCon {
    height: 550px;
    overflow: auto;
    border: 1px solid #dedede;
    border-radius: 7px;
    position: relative;
    padding-top: 50px;
    .title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #004168;
      font-size: 20px;
      color: #fff;
      line-height: 50px;
      text-align: center;
    }
  }
  .swiper-container {
    padding: 39px;
  }
}
.ysxiangmu {
  background: url(~@/assets/ysxm1.jpg) center center no-repeat;
  .headTit {
    color: #fff;
    p {
      color: #fff;
    }
  }
  .swiper-container {
    padding: 10px 0;
  }
  .swiperNav {
    position: absolute;
    top: calc(50% - 25px);
    left: 10px;
    width: 50px;
    height: 50px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 24px;
    }
    &.swiper-next {
      left: auto;
      right: 10px;
    }
  }
  .lis {
    cursor: pointer;
    box-shadow: -8px 0px 8px rgba($color: #000000, $alpha: 0.2);
    .imgbox {
      height: 360px;
      transition: ease 0.5s;
    }
    &:hover {
      .imgbox {
        transform: scale(1.1) !important;
      }
    }
  }
  .text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba($color: #ffffff, $alpha: 0.7);
    padding: 20px 10px;
    height: 120px;
    h3 {
      color: #f29810;
    }
    p {
      color: #333;
    }
  }
}
</style>


<style lang="scss">
.videoBlo {
  background: transparent;
  box-shadow: none;
  .el-dialog__header {
    height: 40px;
    .el-dialog__headerbtn {
      right: 0;
    }
    i {
      color: #fff;
      font-size: 20px;
    }
  }
  .el-dialog__body {
    padding: 0px !important;
  }
}
</style>